import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        modalHapus: false,
        info: {
            info_id: '',
            info_nama: '',
            info_ket: '',
            info_file: '',
            info_created_at: '',
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.modalHapus = value
        },
        viewModal(state, value) {
            state.info = value
        }
    }
})
